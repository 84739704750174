import { makeObservable, observable, action, runInAction } from 'mobx';
import {
  FilterCategory,
  PaginationConfig,
  QuickFilterItem,
  SelectedFilterIds,
  TableColumn,
  TableColumnConfigurable,
  generatePages,
  toggleColumns,
} from '../modals/table.modal';
import { FormGroup } from '@angular/forms';
import { FormSection } from '../../shared/models/form.config';
import { UiStore } from './ui.store';

export abstract class MainItemListBaseStore<T> {
  items: T[] = [];
  sortOrder: string = 'asc';
  searchQuery: string = '';

  abstract sortField: string;
  abstract columns: TableColumnConfigurable[];
  abstract quickFilters: QuickFilterItem[];
  abstract filterCategories: FilterCategory[];

  paginationConfig: PaginationConfig = {
    currentPage: 1,
    pageSize: 10,
    totalPages: 1,
    totalCount: 0,
    pageSizeOptions: [1, 2, 10],
    pages: [],
  };

  //form
  isSideFormOpen: boolean = false;
  formGroup = new FormGroup({});
  fields: FormSection[];

  constructor() {
    makeObservable(this, {
      items: observable,
      sortOrder: observable,
      searchQuery: observable,
      paginationConfig: observable,
      setSort: action,
      setSearchQuery: action,
      setPage: action,
      loadItems: action,
      resetEntireState: action,
      toggleColumn: action,
      getEnabledColumns: observable,
      formGroup: observable,
    });
  }

  abstract get service(): any;
  abstract resetEntireState(): void;
  abstract getMasterLists(): void;

  setSort(event: { field: string; order: string }): void {
    this.sortField = event.field;
    this.sortOrder = event.order;
    this.loadItems();
  }

  setSearchQuery(query: string): void {
    this.searchQuery = query;
    this.loadItems();
  }

  setPage(page: number): void {
    this.paginationConfig.currentPage = page;
    this.loadItems();
  }

  loadItems(): void {
    const activeFilter = this.quickFilters.find((filter) => filter.isActive);
    const activeFilterKey = activeFilter ? activeFilter.key : undefined;
    const dynamicFilters = this.getAllSelectedIds();
    this.service
      .list(
        this.sortField,
        this.sortOrder,
        this.paginationConfig.currentPage,
        this.paginationConfig.pageSize,
        this.searchQuery,
        activeFilterKey,
        dynamicFilters
      )
      .subscribe({
        next: (data: any) => {
          runInAction(() => {
            this.items = data.items;
            this.paginationConfig.totalPages = data.pagination.total_pages;
            this.paginationConfig.totalCount = data.pagination.total_count;
            this.paginationConfig.pages = generatePages(
              this.paginationConfig.currentPage,
              this.paginationConfig.totalPages
            );
          });
        },
        error: (error: any) => {
          console.error('Failed to load data:', error);
        },
      });
  }

  toggleColumn(field: string): void {
    toggleColumns(this.columns, field);
  }

  getEnabledColumns(): TableColumn[] {
    return this.columns.filter((column) => column.isEnable);
  }

  getAllSelectedIds(): SelectedFilterIds {
    const selectedIds: SelectedFilterIds = {};

    this.filterCategories.forEach((category) => {
      selectedIds[category.key] = category.options
        .filter((option) => option.isSelected)
        .map((option) => parseInt(option.id));
    });

    return selectedIds;
  }
}
